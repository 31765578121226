// フォーム バリデーション
$(function(){
    let isError = true;
    let validator = $("#trialForm").validate({
    rules: {
        first_name: {
            required: true,
        },
        last_name: {
            required: true,
        },
        tel: {
            required: true,
        },
        email: {
            required: true,
            email: true,
        },
        schedule1: {
            required: true,
        },
        schedule2: {
            required: true,
        },
        personal_info: {
            required: true,
        },
    },
    messages: {
        first_name: {
            required: "入力が<br>必要です",
        },
        last_name: {
            required: "入力が<br>必要です",
        },
        tel: {
            required: "入力が<br>必要です",
        },
        email: {
            required: "入力が<br>必要です",
            email: "正しい値を<br>入力して下さい",
        },
        schedule1: {
            required: "入力が<br>必要です",
        },
        schedule2: {
            required: "入力が<br>必要です",
        },
        personal_info: {
            required: "同意が必要です",
        },
    },
    errorPlacement: function (error, element) {
        if (element.attr("name").match(/(personal_info)/)) {
            error.addClass('check');
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    },
    success: function(error, element) {
        $('#trialForm').on('submit', function(e){
        if (validator.numberOfInvalids() == 0 && isError) {
            isError = false;
            $(this).css('pointer-events','none');
            $(this).css('opacity','0.7');
            onSubmit(e);
        }
        })
    },
    });

    function onSubmit(e) {
    	e.preventDefault();
        const SITE_KEY = '6LcQfg4hAAAAAC7oScbq-z_FXAKMdJ6H3Et0hxZI';
    	grecaptcha.ready(function()
    	{
    		grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(function(token)
    		{
    			var recaptchaToken = document.getElementById('recaptchaToken');
    			recaptchaToken.value = token;
    			document.getElementById("trialForm").submit();
    		});
    	});
    }

})
